<template>
    <section class="component-donuts">
        <p class="text-black f-500 f-20 mb-2">
            Vehículos
        </p>
        <div class="row mx-0">
            <div v-if="verGrafica" class="col-12 px-0">
                <echart :options="vehiculos" />
            </div>
            <div v-else class="col-12 px-0 text-center">
                <img class="obj-cover" src="/img/no-imagen/no-grafica.png" />
            </div>
        </div>
    </section>
</template>
<script>
const colorsGender = [ "#B2B2FF" /* Camionetas */ , "#FFB2C2" /* Carros */ , "#80DCFF" /* Motos */ ]

import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);

export default {
    props: {
        data: {
            type: Array,
            default: []
        },
    },
    data(){
        return {
            verGrafica: false,
            vehiculos: {
                tooltip: {
                    show: false,
                },
                color: colorsGender,
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '70%'],
                        labelLine: {
                            show: true
                        },
                        label: {
                            formatter: (params) => {
                                let total = this.data[params.dataIndex].total;
                                return `${params.name} \n ${total}: ${params.value}%`
                            },
                            show: true,
                            fontSize: '15',
                        },
                        hoverAnimation: false,
                        data: [
                            { value: 0, name: 'Camionetas'},
                            { value: 0, name: 'Carros'},
                            { value: 0, name: 'Motos'},
                        ]
                    }
                ]
            },
        }
    },
    watch:{
        data(){
            this.mapearData();
        },
    },

    mounted(){
        this.mapearData();
    },

    methods: {
        mapearData(){
            if(this.data.length < 1)return this.verGrafica = false;
            let info = this.data;
        
            this.vehiculos.series[0].data[0].value = `${info[0].porcentaje}`;
            this.vehiculos.series[0].data[0].name = 'Camionetas';

            this.vehiculos.series[0].data[1].value = `${info[1].porcentaje}`;
            this.vehiculos.series[0].data[1].name = 'Carros';

            this.vehiculos.series[0].data[2].value = `${info[2].porcentaje}`;
            this.vehiculos.series[0].data[2].name = 'Motos';

            this.verGrafica = true;
        },
    },
}
</script>
